body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: #f0f2f5;
  min-height: 1vh;
}

.pagination {
  list-style: none;
  display: flex;
}

.pagination > li {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f0f0f0;
  width: 30px;
  height: 30px;
  margin: 0 3px;
  cursor: pointer;
}

.pagination > link > a {
  color: black;
}

.pagination > li:hover {
  background-color: #fafafa;
}

.pagination > .active {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1890ff;
  width: 30px;
  height: 30px;
  margin: 0 3px;
}
